import React, { useEffect, useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import Media from "react-media";
import Helmet from "react-helmet";

// Context
import WindowWidthContext from "../../../components/context/window-width";
import StoreContext from "../../../components/context/store";
import { FooterColor } from "../../../components/context/footer-color";

// Components
import Layout from "../../../components/account/Layout";
import { AccountNavigation } from "../../../components/account/account-navigation";
import { MobileAccountNavigation } from "../../../components/account/mobile-account-navigation";
import { TradeAccountEnquiryForm } from "../../../components/forms/trade-account-enquiry-form";

import {
	Page,
	AccountContainer,
	ContentContainer,
} from "../../../components/trade/trade-components";

import { tertiary } from "../../../components/utils/colors";

// Queries
const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			tags
		}
	}
`;

const FormContainer = styled.div`
	background-color: ${tertiary};
	padding: 60px 0 0 0;

	width: 100%;

	& .account-container {
		margin: 0 0 0 150px;

		@media (max-width: 1024px) {
			margin: 0;
		}
	}
`;

const Index = ({ location, data }) => {
	const windowWidth = useContext(WindowWidthContext);
	const { customerAccessToken } = useContext(StoreContext);
	const tradeData = data.prismicAccounts.data;

	const [footerColor, setFooterColor] = useContext(FooterColor);
	useEffect(() => {
		setFooterColor(`#fff`);
	}, []);

	return (
		<Layout>
			{customerAccessToken !== null && (
				<Query
					query={CUSTOMER_INFO}
					variables={{
						customerAccessToken: customerAccessToken.accessToken,
					}}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">Fetching</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
						if (error) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">
												Error loading page. Please try refreshing.
											</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}

						if (error) {
							return <></>;
						}

						const isTrade =
							data.customer.tags !== undefined
								? data.customer.tags.includes("trade") || data.customer.tags.includes("Trade")
								: false;

						if (isTrade === false && typeof window !== "undefined") {
							return <>{navigate(`/account`)}</>;
						} else {
							return (
								<Page>
									<Helmet title={`Trade Account | Christian Watson`} />

									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>

									<Media
										queries={{
											medium: "(max-width: 1024px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1024,
										}}
										render={() => <MobileAccountNavigation />}
									/>

									<AccountContainer>
										<ContentContainer>
											<div className="section">
												<div
													className="account-text"
													dangerouslySetInnerHTML={{
														__html: tradeData.trade_account_text.html,
													}}
												/>
											</div>
											<div className="section">
												<div
													className="account-text"
													dangerouslySetInnerHTML={{
														__html: tradeData.contact_us_text.html,
													}}
												/>
											</div>
										</ContentContainer>
									</AccountContainer>

									<FormContainer>
										<AccountContainer className="account-container">
											<ContentContainer>
												<TradeAccountEnquiryForm
													content={tradeData.trade_enquiry_form.document}
												/>
											</ContentContainer>
										</AccountContainer>
									</FormContainer>
								</Page>
							);
						}
					}}
				</Query>
			)}
		</Layout>
	);
};

export const query = graphql`
	{
		prismicAccounts {
			data {
				trade_account_text {
					html
				}
				contact_us_text {
					html
				}
				trade_enquiry_form {
					document {
						... on PrismicEnquiryForm {
							id
							data {
								form_title {
									html
								}
								form_text {
									html
								}
								form_message_placeholder
								form_disclaimer_text {
									html
								}
								dropdown_options {
									dropdown_option
									linked_email_address
								}
								additional_form_fields {
									additional_form_field
								}
								enable_file_upload
								file_upload_text {
									html
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default Index;
